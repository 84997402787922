import React, { useEffect, useState } from "react"
import axios from "axios"
import { useFormik } from "formik"
import { navigate } from "gatsby"
import {
  StyledForm,
  StyledFieldset,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
  StyledFormElementWrapper,
  StyledTextArea,
  ErrorMessage,
  StyledSelectWrapper
} from "../styledComponents/contact"
import { StyledButton } from "../styledComponents/button"

const CoachingConsultationForm = () => {
  const [token, setToken] = useState("") // store token
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state
  const [isFormValid, setIsFormValid] = useState(false)
  const [isHowHeardOther, setIsHowHeardOther] = useState(false);

  // Validation
  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = "Required"
    }
    if (!values.lastName) {
      errors.lastName = "Required"
    }

    if (!values.telephone){
      errors.telephone = "Required"
    }
    if (!values.dob) {
      errors.dob = "Required"
    }

    if (!values.occupation) {
      errors.occupation = "Required"
    }
   
    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }

   
    Object.keys(errors).length ? setIsFormValid(false) : setIsFormValid(true)
    return errors
  }

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `https://adminwp.karinagrant.co.uk/wp-json/jwt-auth/v1/token`,
      data: {
        username: `contactform`, // provide a user credential with subscriber role
        password: `qR9zizJq^qKbv2#5nopD8ly#`,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      dob: "",
      occupation: "",
      howHear: "",
    },
    validate,
    onSubmit: (
      {
        firstName,
        lastName,
        email,
        telephone,
        dob,
        occupation,
        howHear
      },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      
      const bodyFormData = new FormData()
      bodyFormData.set("firstName", firstName)
      bodyFormData.set("lastName", lastName)
      bodyFormData.set("email", email)
      bodyFormData.set("telephone", telephone)
      bodyFormData.set("dob", dob)
      bodyFormData.set("occupation", occupation)  
      bodyFormData.set("howHear", howHear)  
      // here we sent
      axios({
        method: "post",
        url: `https://adminwp.karinagrant.co.uk/wp-json/contact-form-7/v1/contact-forms/6277/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
          navigate("/thank-you")
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  // useEffect(() => {
  //   // set timeout 3 seconds to remove error/success message.
  //   setTimeout(() => {
  //     // this will reset messageSent and isSuccessMessage state
  //     setMessageSent(false)
  //     setIsSuccessMessage(false)
  //   }, 3000)
  //   // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  // }, [isSuccessMessage, messageSent])

  return (
    <StyledForm name="coaching-consultation" method="POST" data-netlify="true" action="thankyou?coaching=1">
      <StyledInput type="hidden" name="form-name" value="coaching-consultation" />
    <StyledFieldset>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="firstName">
          <StyledLabelHeader
            required
            isEmpty={errors.firstName && touched.firstName}
          >
            First Name
          </StyledLabelHeader>
          <StyledInput
            id="firstName"
            name="firstName"
            type="text"
            onChange={handleChange}
            value={values.firstName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.firstName && touched.firstName ? (
          <span className="error-message">{errors.firstName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="lastName">
          <StyledLabelHeader
            required
            isEmpty={errors.lastName && touched.lastName}
          >
            Last Name
          </StyledLabelHeader>
          <StyledInput
            id="lastName" 
            name="lastName"
            type="text"
            onChange={handleChange}
            value={values.lastName}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.lastName && touched.lastName ? (
          <span className="error-message">{errors.lastName}</span>
        ) : null}
      </StyledFormElementWrapper>
      <StyledFormElementWrapper>
        <StyledLabel htmlFor="email">
          <StyledLabelHeader
            required
            isEmpty={errors.email && touched.email}
          >
            Email Address
          </StyledLabelHeader>
          <StyledInput
            id="email"
            name="email"
            type="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur("email")}
            required
          />
        </StyledLabel>
        {errors.email && touched.email ? (
          <span className="error-message">{errors.email}</span>
        ) : null}
      </StyledFormElementWrapper>

      <StyledFormElementWrapper>
          <StyledLabel htmlFor="telephone">
            <StyledLabelHeader
            required
            isEmpty={errors.telephone && touched.telephone}
          > 
            Phone Number</StyledLabelHeader>
            
            <StyledInput
              id="telephone"
              name="telephone"
              type="tel"
              onChange={handleChange}
              value={values.telephone}
              placeholder="+44 (0)7400 123456"
              onBlur={handleBlur("telephone")}
              required
            />
          </StyledLabel>
          {errors.telephone && touched.telephone ? (
          <span className="error-message">{errors.telephone}</span>
        ) : null}
        </StyledFormElementWrapper>
      
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="dob">
            <StyledLabelHeader
            required
            isEmpty={errors.dob && touched.dob}
          > 
            Date of birth</StyledLabelHeader>
            
            <StyledInput
              id="dob"
              name="dob"
              type="date"
              onChange={handleChange}
              value={values.dob}
              placeholder="+44 (0)7400 123456"
              onBlur={handleBlur("dob")}
              required
            />
          </StyledLabel>
          {errors.dob && touched.dob ? (
          <span className="error-message">{errors.dob}</span>
        ) : null}
        </StyledFormElementWrapper>

        <StyledFormElementWrapper>
        <StyledLabel htmlFor="occupation">
          <StyledLabelHeader
            required
            isEmpty={errors.occupation && touched.occupation}
          >
            Occupation
          </StyledLabelHeader>
          <StyledInput
            id="occupation"
            name="occupation"
            type="text"
            onChange={handleChange}
            value={values.occupation}
            onBlur={handleBlur}
            required
          />
        </StyledLabel>
        {errors.occupation && touched.occupation ? (
          <span className="error-message">{errors.occupation}</span>
        ) : null}
      </StyledFormElementWrapper>

    
      <StyledSelectWrapper>
        <StyledFormElementWrapper>
          <StyledLabel htmlFor="howHear">
            <StyledLabelHeader>How did you hear about Karina?</StyledLabelHeader>
            <StyledTextArea
              rows="3"
              id="howHear"
              name="howHear"
              type="text"
              onChange={handleChange}
              value={values.howHear}
              onBlur={handleBlur}
              placeholder="Was it through a website, a friend, or social media? Karina would love to know how you found out about her."
              required
            />
          </StyledLabel>
        </StyledFormElementWrapper>
      </StyledSelectWrapper>
        
    </StyledFieldset>
    <StyledButton
      type="submit"
      value="Send Message"
      disabled={isSubmitting || !isFormValid}
    >
      Submit
      </StyledButton>
    {messageSent && !isSuccessMessage && (
      <ErrorMessage>
        <p>Something went wrong please try again.</p>
      </ErrorMessage>
    )}
  </StyledForm>
  )
}

export default CoachingConsultationForm
