import React from "react"
import {
  Section
} from "../styledComponents/section"
import { ContactSection, ContactSectionHeading, ContactSectionLedeDiv } from "../styledComponents/contact"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CoachingConsultationForm from "../components/formConsultationCoaching"

const Heading = ({ level = 1, location, children }) => (
  <ContactSectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </ContactSectionHeading>
)

const CoachingConsultation = ({ data, location }) => {
  return (
    <>
    <Layout displayNavBorder location={location}>
      <SEO 
        title="Coaching Consultation Form" 
        description="Coaching sessions are a mixture of talk based coaching combined with using techniques from Permissioning and Transformation and Quantum-Touch. This is suitable for people wanting to use these techniques towards specific life goals and to gain clarity on any aspect of life."
        url="/coaching-consultation"
        />
      <Section>
        <Heading level={1}>
          Consultation Form
        </Heading>
        <ContactSectionLedeDiv>
          <p>Please fill in the following form:</p>
        </ContactSectionLedeDiv>
        <ContactSection>
          <CoachingConsultationForm />
        </ContactSection>
      </Section>
    </Layout>
    </>
  )
}

export default CoachingConsultation
